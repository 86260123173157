button.d-xl-none.btn.btn-primary.rounded-pill.p-1.pt-0 {
    display: none;
}

.search-form-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 80%;
    margin: 0 auto;
    background: #fff;
    border: 1px solid rgb(221, 221, 221) !important;
    border-radius: 60px;
    position: relative;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px !important;
    z-index: 9;
}

.search-form-box .search-form-item {
    width: 28%;
    max-width: 28%;
    position: relative;
    overflow: hidden;
}

.search-form-box .search-form-item-date-range {
    width: 28%;
    max-width: 28%;
    position: relative;
    overflow: hidden;
}

.rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    width: 100% !important;
    height: 66px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    height: 66px;
    padding: 25px 55px 25px 15px !important;
    border: none;
    border-radius: 0px;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 25px !important;
}

button.search-btn {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    background: #eaba42;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
}

.search-form-item:after {
    content: "";
    position: absolute;
    width: 1%;
    height: 42px;
    right: 0;
    top: 12px;
    border-right: 2px solid #eaba3a;
    z-index: 9;
}

.search-form-item:last-child(4)::after {
    content: "";
    position: absolute;
    width: 1%;
    height: 42px;
    right: 0;
    top: 20px;
    border-right: none;
    z-index: 9;
}

span.rs-picker-toggle-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
}