.result-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 10px;
}

.iq-navbar {
    z-index: 99999;
}

.result-header-o {
    position: sticky;
    top: 64px;
    background: #fff;
    box-shadow: 0px 2px 3px #00000021;
    z-index: 9;
}

.result-header p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.result-header button {
    background: #fff;
    border: 1px solid #b6b6b6;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 600;
}

.result-header-warn {
    padding: 20px;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    display: flex;
    align-items: center;
}

.map-loader {
    background: #f6f6f6;
    width: 100%;
    height: 100vh;
}

.home-text3 button {
    padding: 5px 15px;
    border-radius: 10px;
    transition: all .4s;
}

span.home-text3 button {
    padding: 5px 15px;
    border-radius: 10px;
    transition: all .4s;
}

span.home-text3 button:hover {
    background: #201f1f;
    color: #fff;
}

.home-text3 button:hover {
    background: #201f1f;
    color: #fff;
}

.home-container.one-view-selected .home-container3 {
    background: #32323226;
    padding: 10px;
}

.home-container.one-view-selected .home-container3 a i {
    color: #fff !important;
    margin-left: 5px;
}

.home-container.one-view-selected .home-container3 span.home-text3 button {
    background: #00000029;
    color: #000;
    font-weight: 600;
}

.home-container.one-view-selected .home-container3 .home-button {
    background-color: #000;
    color: #ffffff;
    align-self: flex-end;
    border-width: 0px;
    text-transform: uppercase;
    font-weight: 600;
}

.result-header-o.col-md-12 button i {
    font-size: 22px;
}

.m-l-2 {
    margin-left: 20px;
}