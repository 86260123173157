.main-content .content-inner {
    min-height: calc(100vh - 8.6rem);
}

.footer .footer-body {
    display: flex;
    flex-wrap: initial;
    justify-content: space-between;
    padding: 0;
}

.left-panel img {
    width: 150px;
    margin-bottom: 0px;
}

.left-panel, .center-panel, .right-panel {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.footer .footer-body .list-inline-item {
    display: block;
    margin-bottom: 5px;

}

.footer .footer-body .list-inline-item a{
    color: #0000007a
}

.footer .footer-body h3{
    font-size: 16px;
    /* padding-left: 39px; */
    font-weight: 700;
}

.region-copyright {
    padding: 15px;
    border-top: 1px solid #ededed;
}