@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
    justify-content: center;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label,
div,
li {
  font-family: 'Montserrat', sans-serif;
}

.top-header {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-family: 'montserrat', sans-serif;
  font-weight: 600;
}

.top-header-box {
  background: #eaba3a;
  position: relative;
  z-index: 99;
}

.top-header a {
  color: #fff;
}

.navbar-brand img {
  width: 120px;
}

.nav {
  background: #3b3c3c;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-weight: 600;
}

.navbar-nav {
  align-items: center;
}

div#navbarSupportedContent {
  width: 25%;
}

nav#navbar_main {
  width: 50%;
}

nav#navbar_main .navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
  justify-content: flex-end;
}

nav#navbar_main .navbar-nav li a {
  background: #fff;
  color: #000;
  padding: 8px 15px;
  margin: 0 5px;
  border-radius: 20px;
}

.anonymous-link {
  background: #fff;
  color: #000 !important;
  padding: 8px 15px;
  margin: 0 5px;
  border-radius: 20px;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgb(234 186 66);
  text-decoration: none;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.navbar-brand.login img {
  height: 50px;
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.auth-card-o {
  padding: 20px;
}

.login-content a {
  color: #eaba42 !important;
}

a.navbar-brand img {
  width: auto;
  height: 27px;
}

.iconbox-icon img {
  height: 70px;
  width: 67px;
}

section.template-title.has-over.text-over-top.text-center {
  margin-top: 50px;
  margin-bottom: 50px;
}

section.features-box {
  background: #3b3c3c;
  padding: 50px;
}

section.features-box .container-title-box {
  margin-bottom: 50px;
}

section.features-box {
  color: #fff;
  font-weight: 400;
}

.container-title-box p {
  font-size: 16px;
  width: 40%;
  font-weight: 500;
  margin: 0 auto;
}

.iconbox-content p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled).active,
.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled)[aria-expanded='true'] {
  background-color: #4ec4f9 !important;
  color: #ffffff !important;
  font-weight: 600;
  box-shadow: none !important;
}

.sidebar-default .navbar-nav .nav-item .nav-link:hover,
.sub-nav-item-active {
  background-color: #16181a;
  color: #4ec4f9 !important;
  text-decoration: none;
  font-weight: 600;
  box-shadow: none;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled).active.depot-name {
  background-color: #3b3c3c !important;
  color: #ffffff !important;
  box-shadow: 0 10px 20px -10px rgb(59 60 60) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #3b3c3c;
  color: #3b3c3c !important;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.rs-picker-value-count {
  margin: 0 4px;
  background-color: #eaba42 !important;
  background-color: #eaba42 !important;
  color: #fff;
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 45px !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.hero-section {
  position: relative;
  height: 450px;
  overflow: hidden;
}

.hero-section .search-form-box {
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.hero-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0.3;
}

.wel-box {
  position: absolute;
  top: 30%;
  color: #fff;
  line-height: 0;
  z-index: 2;
}

.hero-section .container {
  width: 80%;
}

.wel-box h1 {
  font-size: 62px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.5;
}

.wel-box p {
  font-size: 18px;
  font-weight: 500;
}

.block-title {
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: 50px;
  position: relative;
}

.block-title:before {
  content: '';
  position: absolute;
  width: 75px;
  height: 6px;
  top: 50px;
  background: #eaba3a;
}

.section-4 {
  position: relative;
  display: block;
  background: #eaba3a;
  text-align: center;
  padding: 10px 20px 50px;
  margin: 50px 0 0;
  color: #fff;
}

.section-4 .block-title {
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: 0px;
  position: relative;
}

.section-4 .block-title:before {
  position: absolute;
  width: 55px;
  height: 4px;
  top: 60px;
  background: #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.block-t-subtitle {
  width: 55%;
  color: #fff;
  margin: 35px auto 35px;
  font-weight: 400;
  font-size: 16px;
}

.btn.btn-yellow {
  color: #fff;
  background-color: #eaba42;
  border-color: #eaba42;
  font-weight: 600;
}

.text-yellow {
  color: #eaba42;
  font-weight: 600;
}

.text-white {
  color: #fff;
  font-weight: 600;
}

/* .bg-success {
  background-color: #eaba42 !important;
  border-color: #eaba42 !important;
  color: #fff;
  font-weight: 600;
} */

.bg-yellow {
  background-color: #eaba42 !important;
  border-color: #eaba42 !important;
  color: #fff;
  font-weight: 600;
}

.bg-grey {
  background-color: #ececec;
  border-color: #ececec;
}

.p-sticky {
  position: sticky;
  top: 0;
}

.p-sticky-10 {
  position: sticky;
  top: 10px;
}

.p-sticky-65 {
  position: sticky;
  top: 65px;
}

.p-sticky-40 {
  position: sticky;
  top: 40px;
}

.h-850 {
  height: 850px !important;
}

.h-950 {
  min-height: 950px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-1 {
  margin-right: 20px !important;
}

.active-layout {
  background: #eaba42 !important;
  color: #fff;
  border-color: #eaba42 !important;
}

.block-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.block-title-box button {
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  background: #eaba42;
  color: #fff;
}

.search-form-drawer.rs-drawer-top.rs-drawer-xs {
  height: 160px;
}

.search-form-drawer .rs-drawer-content {
  background-color: #eaba42;
}

.rs-checkbox-group-inline {
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
  flex-wrap: wrap;
}

/* testimony styles */
.content-temoignage-box {
  background: #fff;
  padding: 20px;
  margin: 0 15px 0 15px;
  border-radius: 10px;
  height: 365px;
}

.body-temoignage {
  display: block;
  width: 100%;
  height: 195px;
  padding: 0 0 50px;
  border-bottom: 1px solid #efefef;
  color: #000;
  margin-top: 0;
}

.img-fonct-nom-temoignage {
  position: relative;
  top: -50px;
  line-height: 1.3;
}

.img-temoignage {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 4px #0000001f;
}

.img-temoignage img {
  width: 100%;
  height: auto;
}

.nom-temoignage {
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
}

.fonct-temoignage {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
}

.less-bg-red {
  background: #cb0f0f0d !important;
  border: 1px solid #cb0f0f47 !important;
  border-radius: 10px !important;
}

.less-bg-yellow {
  background: #eaba4208 !important;
  border: 1px solid #eaba42 !important;
  border-radius: 10px !important;
}

.h-100vh {
  height: 100vh;
}

/* user account style */

.info-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-box-content {
  display: flex;
  flex-direction: column;
}

.info-box-content label {
  font-size: 16px;
  font-weight: 600;
}

.info-box button {
  background: transparent;
  font-weight: 600;
  text-decoration: underline;
}

.info-edit-box input,
.info-edit-box select {
  border: 1px solid #797979 !important;
  height: 60px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.info-edit-box button {
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  padding: 15px 20px;
}

.info-item-box {
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
}


/* animation style */
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


.w-70 {
  width: 70% !important;
}

.end-of-checkout {
  margin-top: 100px;
}

.end-of-checkout img {
  width: 150px;
  margin-bottom: 40px;
}

.end-of-content strong {
  color: #eaba3a;
}

.btn-new-o {
  border-radius: 25px;
  margin: 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 10px 50px;
  position: relative;
  border: 2px solid #eaba3a;
  color: #fff;
  background-color: #eaba3a;
  transition: .3s ease-out;
}

.eof-footer {
  background: #eaba3a;
  color: #fff;
  padding: 20px;
}

.end-of-checkout .eof-footer img {
  width: 70px;
  margin-bottom: 0;
}

.eof-footer p.main-text {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
}

.eof-footer p.sup-text {
  font-size: 21px;
}

.eof-footer p strong {
  color: #000;
}

.section-1 {
  margin-bottom: 50px
}

.reservation-item {
  background-color: #fff !important;
}

.reservation-item .accordion-header {
  background-color: #fff !important;
}

.accordion .accordion-item .accordion-header .accordion-button {
  background: #fff;
  color: #252525;
}

.reservatin-sum {
  padding: 0;
  background: #eaba42;
  color: #030303;
  border-radius: 10px;
}

.reservatin-sum li {
  display: inline-block;
  margin: 10px;
}

.indic-action button {
  background: #fff;
  border: 1px solid #b6b6b6;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
}

.saving-btn {
  background: #eaba42 !important;
  border: none !important;
  font-size: 18px !important;
  color: #fff;
}

.admin-side-bar .navbar-nav {
  flex-direction: column;
  justify-content: center;
  align-items: initial;
}

ul.layout-selection-box {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

ul.layout-selection-box li {
  display: inline-block;
  margin: 10px;
  text-align: center;
}

ul.layout-selection-box li img {
  width: 135px;
  height: 70px;
}

ul.layout-selection-box li .default-layout {
  display: block;
  width: 140px;
  height: 90px;
}

ul.layout-selection-box li button {
  background: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #f9f9f9;
  /* transition: ease-in .3s; */
}

ul.layout-selection-box li button:hover,
.layout-selected {
  border: 2px solid #3db9ff !important;
}

.layout-selected-o {
  border: 2px solid #3db9ff !important;
}

ul.layout-selection-box li button.layout-unselected:hover {
  border: 2px solid transparent !important;
}

ul.layout-selection-box li p {
  font-weight: 500;
  margin-top: 5px;
}

.entAvatar span {
  background: #000000;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

.form-item {
  margin: 10px 0;
}

.entityBox.space-style {
  flex-direction: column;
}

.space-style .entityListBar {
  width: 100%;
}

.space-style .entityList {
  flex-direction: row;
}

.space-style .entityListScrollbar {
  height: auto;
}

.space-style .SingleEntityButton {
  width: 16.66%;
  background: #f4f4f4;
}

.partner-side .space-style .SingleEntityButton {
  width: 20%;
}

.space-style .SingleEntityButton.selected {
  background: #4ec4f9;
}

.space-style .SingleEntityButton.selected .entAvatar span {
  background: #fff;
  color: #070707;
}

.space-style .SingleEntityButton.selected .SingleEntityName {
  color: #fff;
}

.form-item label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-item input,
.form-item select {
  background: #f4f4f4;
  height: 50px;
}

.form-item textarea {
  background: #f4f4f4;
}

.entityBoxWrapper.ent-layout {
  border-top: none;
}

/* admin style */
.sidebar {
  display: block;
  position: fixed;
  width: 100%;
  /* max-width: 16.2rem; */
  top: 0;
  bottom: 0;
  z-index: 910;
  transition: all 400ms ease;
  background-color: #16181a;
  box-shadow: 0 0px 30px 0 rgba(17, 38, 146, 0.05);
}

.admin-side .nav {
  background: #ececec;
}

.main-content.bg--grey.admin-side {
  background: #ececec;
}

.sidebar-default .navbar-nav .nav-item .nav-link:not(.disabled) {
  background: transparent !important;
  color: #bfbfbf;
  font-weight: 600;
}


.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  width: 100%;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
  transition: border .24s ease-in-out;
}

.dropzonebox .thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px
}

.dropzonebox .thumb {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: 100px;
  padding: 4px;
  box-sizing: border-box
}

.dropzonebox .thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden
}

.dropzonebox .thumbInner-img {
  display: block;
  width: auto;
  height: 80px;
  margin-right: 10px;
}

.dropzonebox ul {
  list-style: none;
  padding: 0;
}

.dropzone--box .dropzonebox .dropzone {
  height: 350px
}

.tables_paginate.paging_simple_numbers .pagination {
  padding: 0;
  text-align: center;
  margin: 2px 0;
  white-space: nowrap;
  justify-content: center;
}

.tables_paginate.paging_simple_numbers .pagination li {
  display: inline
}

.tables_paginate.paging_simple_numbers .pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
.tables_paginate.paging_simple_numbers .pagination li a {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s
}

.tables_paginate.paging_simple_numbers .pagination li.selected a {
  background-color: #4ec4f9;
  color: #fff
}

.tables_paginate.paging_simple_numbers .pagination li a:hover:not(.active) {
  background-color: #ddd;
}

.form-control {
  color: #000000;
}

.reservation-detail .OrderInfo {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px dashed rgb(216, 216, 216);
}

.reservation-detail .BillingInformation {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 60px;
  padding: 0 30px;
}

.reservation-detail .BillingInformation .LeftSideContent {
  width: calc(50% - 50px);
  margin: 0px 10px 0px 0px;
}

.reservation-detail .LeftSideContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.reservation-detail .BillingInformation h3.Title,
.BillingInformation .BillFormTitle {
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 10px;
  line-height: 1;
}

.reservation-detail .BillingInformation .RightSideContent {
  margin: 0px;
}

.reservation-detail .RightSideContent {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
}

.reservation-detail .BillingInformation .RightSideContent {
  width: calc(50% - 50px);
  margin: 0px 10px 0px 0px;
}

.reservation-detail .BillingInformation p.NameEmail span.Name {
  font-size: 15px;
  font-weight: 400;
  display: block;
}

.reservation-detail .TotalBill {
  margin-top: 30px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
  padding-left: inherit;
  padding-right: 30px
}

.reservation-detail .TotalBill p {
  margin-top: 0px;
  font-size: 14px;
  margin-bottom: 15px;
  width: 250px;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.reservation-detail .TotalBill p span {
  width: 120px;
}

.reservation-detail .TotalBill h3 {
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
  width: 250px;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.reservation-detail .TotalBill h3 span {
  width: 180px;
}

.search-form-drawer.admin-side .rs-drawer-content {
  background-color: #16181a;
}

.search-form-drawer.admin-side button.search-btn {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background: #4ec4f9;
  font-size: 21px;
  font-weight: 600;
  color: #fff;
}

.search-form-drawer.admin-side .search-form-item:after {
  content: "";
  position: absolute;
  width: 1%;
  height: 42px;
  right: 0;
  top: 12px;
  border-right: 2px solid #16181a;
  z-index: 9;
}


/* admin search custom style */
/* main.main-content.bg--grey.admin-side {} */

.admin-side .home-button {
  background-color: #16181a !important;
  color: #ffffff;
  align-self: flex-end;
  border-width: 0px;
  text-transform: uppercase;
  font-weight: 600;
}

.admin-side .rating {
  font-size: 16px;
  color: #16181a;
  margin-top: 10px;
}

.admin-side .format-badge {
  position: absolute;
  top: 75%;
  left: 15%;
  background: #16181a;
  color: #fff;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #0000003b;
}

.admin-side .home-container.one-view-selected .home-container3 {
  background: #16181a4e;
  padding: 10px;
}

.admin-side .map-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #4ec4f9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.search-loader-box {
  position: fixed;
  top: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgb(255 255 255 / 64%);
  backdrop-filter: blur(5px);
  z-index: 9;
}

.bg-grey-light {
  background: #f0f0f0
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.mt-20 {
  margin-top: 200px;
}

.w-20 {
  width: 20%;
}

.text-red {
  color: #d24f3f !important
}

.success-icon {
  width: 200px;
}

.storagefile-img {
  width: auto;
  height: 400px;
}

.old-img {
  position: relative;
  background: #e1e1e1;
  display: block;
  text-align: center;
  width: auto;
  height: 400px;
  margin: 20px;
  overflow: hidden;
}

.dropping-box {
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
}

.old-img button,
.dropping-box button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #d6342f;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 4px #8e8e8e;
}

.old-img button svg,
.dropping-box button svg {
  /* background: #fff !important; */
  color: #fff !important
}

.illustration-img {
  background: transparent;
}

.illustration-img img {
  height: 100px;
  border-radius: 10px;
}

.img-l-box {
  width: 230px;
  height: 100px;
  overflow: hidden;
}

.img-l-box img {
  height: 100px;
}

.switch-btn {
  background-color: rgb(207 207 207);
  color: #fff;
  font-weight: 600;
  border: 0px;
  height: 50px;
  padding: 0px 35px;
  margin-left: auto;
  margin-right: inherit;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.switch-btn.mr-2.selected {
  background: #000;
}

.search-title input {
  font-size: 28px
}

.search-title .rs-input-group-addon.rs-input-group-btn.rs-btn.rs-btn-default {
  background: #eaba42;
  color: #fff;
}

.blog {
  background: #f8f8f8;
  margin-bottom: 40px;
  border-radius: 10px;
  min-height: 360px;
  overflow: hidden;
  box-shadow: 0px 0px 8px #19191930;
}

.blog .responsive-image {
  width: 100%;
  height: auto;
}

.blog .blog-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.blog .blog-content {
  padding: 20px;
  border-radius: 10px;
}

.blog .blog-content p {
  color: #313131;
  font-weight: 600;
}

.mobile {
  display: none
}

.not-mobile {
  display: block
}

.admin-side .rs-loader-center {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 10px 10px;
  line-height: 1.66666667;
  background-color: #F5F6FA;
  color: #8A92A6;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  border-bottom: 2px solid transparent !important;
}