div#skeleton-05 {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    justify-content: space-around;
}

#skeleton-05 .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
}

.item-05-01 {
    z-index: 0;
    left: 0px;
    top: -1px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(228, 228, 228);


}

.item-05-02 {
    z-index: 1;
    left: 18%;
    top: 38%;
    position: absolute;
    width: 40%;
    height: 62px;
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;

}

.item-05-03 {
    z-index: 2;
    left: 18%;
    top: 55%;
    position: absolute;
    width: 346px;
    height: 22px;
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 80px, #F4F4F4 150px);
    animation: shine-lines 2s infinite ease-out;
    background-size: 600px;

}


@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 200px;
    }
}