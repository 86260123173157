.partner-side .top-header-box.container-fluid {
    background: #13367c;
}

.partner-side .nav {
    background: #fff;
}

.partner-side .caption h6.caption-title {
    color: #13367c;
}

.partner-side .caption p.caption-sub-title {
    color: #13367ccf;
}

.partner-side .wel-box {
    position: absolute;
    top: 20%;
    color: #fff;
    line-height: 0;
    z-index: 2;
}

.partner-side .wel-box h1 {
    line-height: normal;
}

.partner-side .wel-box span {
    color: #ff3318;
}

.lg-btn {
    padding: 20px 15px;
    min-height: 110px;
    margin: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    text-decoration: none;
    transition: all .4s ease
}

.lg-btn:hover {
    color: #fff;
    text-decoration: none;
    transform: scale(1.1);
}

.bg-blue {
    background: #13367c;
}

.lg-btn span {
    display: block;
    font-size: 11px;
}

.lg-btn svg {
    margin-right: 10px;
}

.partner-side section.features-box {
    background: #ffffff;
    padding: 50px;
}

.partner-side .stat-content {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    color: #13367c;
}

.partner-side .countbox {
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partner-side .count-box span {
    font-size: 38px;
    font-weight: 600;
}

.partner-side .countbox-content {
    font-weight: 500;
    color: #2c2c2c;
}

.partner-title-section {
    background: #13367c;
    text-align: center;
    padding: 50px;
    margin-bottom: 30px;
    position: relative;
}

.partner-title-section .title-section-title {
    position: relative;
    color: #fff;
}

.partner-title-section .title-section-title strong {
    color: #d24f3f;
}

.partner-title-section .breadcrumbs ul li {
    display: inline-block;
    margin: 0 10px;
    color: #d24f3f;
}

.partner-form .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(19 54 124);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.partner-form .text-primary {
    color: #ffffff !important;
}

.partner-blue-link {
    background: #13367c;
    color: #fff !important;
    padding: 8px 20px !important;
    margin: 0 5px;
    border-radius: 20px;
}

.partner-red-link {
    background: #d63430;
    color: #fff !important;
    padding: 8px 20px !important;
    margin: 0 5px;
    border-radius: 20px;
}

.partner-side .home-button {
    background-color: #13367c !important;
    color: #ffffff;
    align-self: flex-end;
    border-width: 0px;
    text-transform: uppercase;
    font-weight: 600;
}

.partner-side .format-badge {
    position: absolute;
    top: 75%;
    left: 15%;
    background: #13367c;
    color: #fff;
    font-weight: 600;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 8px #0000003b;
}

.partner-side .map-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #13367c;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.partner-side .pin-button .map-pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 -6px;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
}

.blue-btn {
    background: #13367c !important;
    border-color: #13367c !important;
    color: #fff
}

.red-btn {
    background: #d6342f !important;
}