@media only screen and (max-width: 768px) {

    .footer .footer-body {
        display: flex;
        flex-direction: column;
        flex-wrap: initial;
        justify-content: space-between;
        padding: 0;
    }

    div#navbarSupportedContent {
        width: 100%;
    }

    .anonymous-link {
        background: #fff;
        color: #000 !important;
        font-size: 12px;
    }

    .hero-section .search-form-box {
        position: relative;
        bottom: 50px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .wel-box {
        position: absolute;
        top: 15%;
        color: #fff;
        line-height: 0;
        z-index: 2;
    }

    .slick-slide img {
        display: block;
        width: auto;
        height: 200px;
    }

    .wel-box p {
        font-size: 14px;
        font-weight: 500;
        line-height: normal
    }

    .wel-box h1 {
        font-size: 24px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        line-height: normal;
    }

    .hero-section {
        position: relative;
        height: 550px;
        overflow: hidden;
    }

    .slick-list {
        position: relative;
        height: 450px;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .hero-section .search-form-box {
        position: absolute;
        bottom: 10px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .search-form-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        width: 90%;
        margin: 0 auto;
        background: #fff;
        border: 1px solid rgb(221, 221, 221) !important;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px !important;
        z-index: 9;
    }

    .search-form-box .search-form-item {
        width: 100%;
        max-width: 100%;
        position: relative;
        overflow: hidden;
    }

    .search-form-box .search-form-item-date-range {
        width: 100%;
        max-width: 100%;
        position: relative;
        overflow: hidden;
    }

    .search-form-item:after {
        content: "";
        position: absolute;
        width: 1%;
        height: 42px;
        right: 0;
        top: 12px;
        border-right: 2px solid #fff;
        z-index: 9;
    }

    .search-form.btn-o {
        width: 100%;
    }

    button.search-btn {
        height: 60px;
        width: 100%;
        border-radius: 50px;
        background: #eaba42;
        font-size: 21px;
        font-weight: 600;
        color: #fff;
    }

    .container-title-box p {
        font-size: 16px;
        width: 100%;
        font-weight: 500;
        margin: 0 auto;
    }

    .iconbox-content p {
        font-size: 12px;
        font-weight: 600;
        margin-top: 20px;
        text-align: center;
    }

    .block-title {
        font-size: 24px;
        margin-top: 50px;
        margin-left: 30px;
        margin-bottom: 50px;
        position: relative;
        line-height: normal;
    }

    .block-title:before {
        content: '';
        position: absolute;
        width: 75px;
        height: 6px;
        top: 50px;
        background: transparent;
    }

    .slick-slide .content-commune-home img,
    .slick-slide .content-type-format-home img {
        display: block;
        width: auto;
        height: 300px;
    }

    .section-1 .slick-list {
        position: relative;
        height: auto;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .g-block-1 .cols-1 .content-commune-home,
    .g-block-2 .cols-2-0 .content-commune-home {
        height: 300px;
        margin-bottom: 40px;
    }

    .block-t-subtitle {
        width: 100%;
        color: #fff;
        margin: 35px auto 35px;
        font-weight: 400;
        font-size: 16px;
    }

    .login-content h2 {
        font-size: 32px;
    }

    .result-header-o button {
        font-size: 0;
        margin-right: 10px !important;
    }

    .rs-drawer-left.rs-drawer-sm,
    .rs-drawer-right.rs-drawer-sm {
        width: 100%;
    }

    .rs-drawer-body {
        position: relative;
        padding: 30px 30px;
        height: 100%;
        overflow: auto;
    }

    .search-form-drawer.rs-drawer-top.rs-drawer-xs {
        height: 400px;
    }

    .home-container.cart-item .home-container4 {
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cart-item .home-container5 {
        width: 100%;
        height: auto;
    }

    .home-container.cart-item .home-container6 {
        flex: 0 0 auto;
        width: 100%;
        min-height: 5px;
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cart-item .home-container6 {
        width: 100%;
        height: auto;
    }

    .home-container.cart-item .home-container7 {
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        display: flex;
        padding: 10px;
        align-items: center;
        align-content: center;
        flex-direction: revert;
        justify-content: space-between;
    }

    .cart-item button.button.less-bg-yellow.mt-2 {
        margin-top: 0 !important;
    }

    .cart-summary {
        margin: 25px 20px 25px;
        background: #3b3c3c;
        border-radius: 15px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }

    .colalign--o .option-extra {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    .colalign--o .option-extra .option-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    .colalign--o .option-extra span {
        font-family: 'Raleway';
        width: 100%;
        font-size: 21px;
        color: #eaba42;
        font-weight: 700;
        text-align: center;
    }

    .cart-page .rs-input-group {
        width: 100% !important;
    }

    .cart-page h3 {
        font-size: 18px;
        line-height: normal;
    }

    .result-header-warn p {
        font-size: 10px;
    }

    .navbar-light .navbar-nav .nav-link {
        color: #000;
        font-weight: 600;
    }

    #skeleton .wrapper {
        width: 100%;
        min-height: 195px;
        position: relative;
    }

    .item-01 {
        z-index: 0;
        left: 0px;
        top: 18.5px;
        position: relative;
        width: 100%;
    }

    .item-02 {
        z-index: 1;
        left: 0px;
        top: 27.5px;
        position: relative;
        width: 85%;
    }

    .item-03 {
        z-index: 2;
        left: 0px;
        top: 37.5px;
        position: relative;
        width: 35%;
    }

    .item-04 {
        z-index: 3;
        left: 0px;
        top: 51px;
        position: relative;
        width: 45%;
    }

    .item-05 {
        z-index: 5;
        left: 0px;
        top: 56.5px;
        position: relative;
        width: 25%;
    }

    .item-06 {
        z-index: 5;
        left: 0px;
        top: 64px;
        position: relative;
        width: 80%;
        margin-bottom: 80px;
    }

    .item-07,
    .item-08,
    .item-09,
    .item-10,
    .item-11 {
        display: none;
    }

    .partner-side .hero-section {
        position: relative;
        height: 200px;
        overflow: hidden;
    }

    .partner-side .stat-content {
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #13367c;
        padding: 20px;
    }

    .search-form-drawer.admin-side button.search-btn {
        height: 60px;
        width: 100%;
        border-radius: 50px;
        background: #4ec4f9;
        font-size: 21px;
        font-weight: 600;
        color: #fff;
    }

    .search-form-drawer.admin-side .search-form-item:after {
        content: "";
        position: absolute;
        width: 1%;
        height: 42px;
        right: 0;
        top: 12px;
        border-right: 2px solid transparent;
        z-index: 9;
    }

    .partner-side .ent-btn.add-btn.ext-btn {
        background-color: rgb(78 196 249);
        color: #fff;
        font-weight: 600;
        border: 0px;
        height: 50px;
        padding: 0px 0px;
        margin-left: auto;
        margin-right: inherit;
        border-radius: 3px;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    }

    .partner-side .result-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .partner-side .iq-navbar .navbar-collapse:not(.offcanvas-collapse) .navbar-nav {
        flex-direction: column;
        float: none;
        align-items: center;
    }

    .partner-side div#navbarSupportedContent {
        background: #efefef;
    }

    .partner-side .navbar-nav .nav-link {
        padding-right: 10px;
        padding-left: 10px;
    }

    /* calendar style */
    .not-mobile {
        display: none
    }

    .mobile {
        display: block
    }

    /* .rs-drawer-open .rs-picker-menu {
        z-index: 1055;
        left: 0 !important;
    }

    .rs-picker-daterange-calendar-group {
        height: 500px;
        min-width: 100%;
    }

    .rs-picker-daterange-menu .rs-calendar {
        display: block;
        height: 274px;
        padding-bottom: 12px;
        min-width: 100%;
    } */
}